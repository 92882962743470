<template>
  <div>
    <index-navbar />
    <main>
		<section class="relative block py-24 lg:pt-0 bg-blueGray-800">
				<contact-component />
		</section>
    </main>
    <footer-component />
  </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ContactComponent from "@/components/Contact/ContactForm.vue";


export default {
  data() {
    return {
	}
  },
  components: {
    IndexNavbar,
    FooterComponent,
	ContactComponent,
  },
};
</script>
