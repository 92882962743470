<template>
  <div>
    <!-- df: <navbar />-->
    <index-navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1532040675891-5991e7e3d0cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80');
          "
        >
		<!--
        <div
          class="absolute top-100 w-full h-full bg-center bg-cover"
          style="
            background-image: url('http://www.kadalle.com/cons.png');
          "
        >
		-->
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-4 mt-16">
                <h1 class="text-white font-semibold text-5xl">
                  Time to adapt to change, globally.
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  At Kadalle, we help your organization embrace digital transformation. <br />
                </p>
              </div>
              <div class="pr-4 mt-8">
                <a class="bg-lightBlue-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 btn-lines dark light wow fadeInUp animated btn btn-default btn-green hvr-bounce-to-right animated" data-wow-delay=".9s" href="/contact" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">Talk to an Expert Now</a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-20">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Application Development</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    We are skilled in developing scalable SaaS products and mobile apps that will be able to handle any type of data input or processing with ease.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Digital Transformation</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Kadalle offers Digital Transformation Services to help you with transformation initiatives across your entire business - from strategy to execution!
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Cloud Migration</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    We offer a wide range of cloud computing services including development of hybrid cloud architectures, deployment of private or public cloud solutions, design and development of building blocks for private or public clouds.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Helping you succeed is our end all
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                No matter where you are in the world, Kadalle is at your service. We offer a wide range of technology consulting services that cover every aspect of your business, allowing you to focus on what you do best.
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
              >
                We are proud to offer you the best in class services that will help you scale, grow, and succeed.
              </p>
              <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Contact Us Today!
              </router-link>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Kadalle understands the challenges of today's competitive market and offers the expertise you need to keep up with today's fast-paced business environment. 
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
              style="
                transform: scale(1.2) perspective(1040px) rotateY(20deg)
                  rotateX(2deg) rotate(2deg);
              "
                src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <!-- df: <div
                  class="text-emerald-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-300"
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div> -->
                <h3 class="text-3xl font-semibold">Global team</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  Founded in 2016, Kadalle is a full-service technology consulting firm servicing clients worldwide. <br /> We achieve excellence by building deep industry expertise and technical skills to help clients specify and implement effective strategies around digital transformation. <br />
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Top-of-the-line technology solutions provider
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Experienced consultants both on-shore/offshore
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Accurate time and cost estimates</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-20 pb-40">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Services</h2>
              <p class="text-lg leading-relaxed m-4 text-blueGray-500">
                We offer a wide range of solutions to help you grow your business. From custom development to marketing and product design, we have the expertise to help you succeed online.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6 py-6 hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg ease-linear transition-all duration-150">
                <img
                  alt="..." src="https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1334&q=80"
                  class="shadow-lg rounded-full1 mx-auto max-w-240-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Cloud Migration</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 font-semibold"
                  >
Moving to the cloud has many benefits. <br />
Not only does it allow you to scale up and down as needed, but it also eliminates the hassle of managing hardware and software issues internally. <br />
Kadalle can help migrate your organization to a secure, scalable cloud environment that lowers your IT costs and improves efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6 py-6 hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg ease-linear transition-all duration-150">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  class="shadow-lg rounded-full mx-auto max-w-200-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Artificial Intelligence </h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 font-semibold"
                  >
                    AI ML have been used successfully in many areas including healthcare, finance, marketing, and many other industries. 
					<br />
					We offer a range of AI solutions from data analytics to machine learning to help drive better decision making for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6 py-6 hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg ease-linear transition-all duration-150">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1606189934846-a527add8a77b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  class="shadow-lg rounded-full1 mx-auto max-w-200-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">App Development</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 font-semibold"
                  >
                    We understand that you need a great app for your business - one that's intuitive and easy to use, but also high-performing and scalable. <br />
					Our expert team can build a mobile app for any platform or device you need - Android apps, iOS apps, Windows Phone apps - it doesn't matter!
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6 py-6 hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg ease-linear transition-all duration-150">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1586448124798-bec4201c06db?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  class="shadow-lg rounded-full1 mx-auto max-w-200-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Master Data Management</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 font-semibold"
                  >
                    We specialize in data integration, data governance and data migration services. We have the expertise to help you with any data-related project, no matter how big or small
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
		<contact-component />
    </main>
    <footer-component />
  </div>
</template>
<script>
//import Navbar from "@/components/Navbars/AuthNavbar.vue";
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ContactComponent from "@/components/Contact/ContactForm.vue";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
    };
  },
  components: {
    IndexNavbar, //Navbar,
    FooterComponent,
	ContactComponent,
  },
};
</script>
