<template>
  <div>
    <index-navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1569390412444-c80c024169f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-4 mt-16">
                <h1 class="text-white font-semibold text-5xl">
                  Services
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Discover how Kadalle can help your enterprise achieve business success by adopting modern digital best practices. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-5/12 ml-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg leftTransformImg"
              style="
              "
                src="https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4 sm:mt-5">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Product Strategy & Engineering</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  We align product design and development to your business goals, focusing on high-quality web application development, data engineering and business intelligence. <br /><br /> Our engineering expertise spans multiple verticals with a particular focus on healthcare, enterprise solutions and SaaS. <br /><br /> Our aim is to help our clients gain competitive advantage by improving operational efficiency and deliver products faster, easier and with very high quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative py-20 bg-black text-white">
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Application Performance Management</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-300">
                  We help you manage your application portfolio, your service performance and both IT and business applications in a cost-effective way. <br /><br /> Our platform combines monitoring, analytics and management into one integrated solution, so you can easily visualize the health and performance of your applications, get alerts to fix problems, respond quickly to users’ complaints and deliver a superior CX.  <br /><br /> We achieve this by utilizing a wide range of built-in intelligence capabilities like automatic root cause analysis (RCA), pattern-based anomaly detection, performance trending, business context prioritization, proactive event correlation and predictive analytics in every deployment.
                </p>
              </div>
            </div>
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
              style="
                transform: scale(1) perspective(1040px) rotateY(-20deg)
                  rotateX(2deg) rotate(12deg);
              "                src="https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1106&q=80"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="relative py-20">
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1587075502171-3e9a1b518347?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              />
            </div>
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Cloud Migration</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                   Your apps need to go from code complete to deployed and into production as quickly as possible to keep pace with the competition, drive innovation, and keep your customers coming back for more. <br /><br /> To help make this a reality, our team of engineers will work with your product team to rapidly identify and setup processes and pipelines that make it easier for you to adopt DevOps practices for delivering software more frequently, at a higher quality and with lower risk. <br /><br />  We integrate with popular cloud providers AWS, Azure, Oracle and Google so that you don't have to worry about provisioning infrastructure.
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Deliver agility to customers
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Deliver more, faster
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Reduce costs and risk</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative py-20 bg-black text-white">
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Staff Augmentation</h3>
                <p class="mt-4 text-lg leading-relaxed  text-blueGray-300">
                  Kadalle provides flexible, highly skilled contractors with deep expertise across industries to serve as the engineering backbone on your dev teams. <br /><br /> Our trusted engineers are able to jump right in, tackle complex challenges and contribute significantly with their past experience and expertise. <br /><br /> Kadalle is your partner in global delivery model via offices in US, India and Nepal to achieve cost-effective solutions.
                </p>
              </div>
            </div>
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              />
            </div>
          </div>
        </div>
      </section>
		<contact-component />
    </main>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ContactComponent from "@/components/Contact/ContactForm.vue";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
	ContactComponent,
  },
};
</script>
