<template>
	<div>
    <index-navbar />
    <main>
	<div >
	<img :src="consImg"  />
	<div class="landing" style="position: absolute;
left: 2%;
bottom: 0%;
font-size: 5em;
color: #fefefe;
font-family: kadalle_script;">Docking soon ... </div>
	</div>
	</main>
	
    <footer-component />
	</div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import consImg from "@/assets/img/cons.png";
export default {
  data() {
    return {
	consImg,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>