<template>
  <div>
    <index-navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1569390412444-c80c024169f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-4 mt-16">
                <h1 class="text-white font-semibold text-5xl">
                  Bringing Humanity to Tech.
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Experience Beautiful, Intuitive and Revolutionary <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-1">
          <div class="items-center flex flex-wrap">
              <div class="text-center  ml-auto mr-auto">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                >
                  Our Mission
                </h3>
                <div
                  class="w-full md:w-8/12 mb-12 text-md leading-normal ml-auto mr-auto text-blueGray-400 font-bold"
                >
                  Our mission is to bring humanity to tech by creating beautiful, intuitive products and experiences that are seamlessly integrated into people’s lives. Our products are simple, artfully designed, and easy to integrate both as standalone products and as a component in your larger ecosystem.

                </div>
              </div>
              <div class="mt-20 mb-12 py-10 border-t border-blueGray-200 text-white bg-df-100 bg-clip-content text-center heartbg" style="
          "><br />
				<i class="fas fa-rocket text-xl" style="margin-top: -30px"></i>
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 mb-2"
                >
                  How We Work
                </h3>
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-2" style="text-align: left;">
                    <p class="mb-4 text-lg leading-relaxed">
                      You want to meet customer expectations. We do too.<br /> That's why we constantly innovate. We operate on an integrated team basis, whereby our engineers are constantly engaged with your developers and business teams during each stage of a project. The result is a more holistic approach, rather than working in silos, which leads to richer and more seamless outcomes.
                    </p>
					<p class="mb-4 text-lg leading-relaxed">
					Using our own technology, we develop new tools, business models and ways of organizing.  As a result, you benefit from market-leading solutions for addressing today's and tomorrow's challenges.
					</p>
					<div class="items-center flex flex-wrap text-lg">
					<div class="w-full md:w-6/12 ml-auto mr-auto px-2"><p><strong>Strategies:</strong></p>
					<ul>
					<li>Business Intelligence</li>
					<li>Lean</li>
					<li>Agile</li>
					<li>Six Sigma</li>
					<li>PMP</li>
					</ul>
					</div>
					<div class="w-full md:w-5/12 ml-auto mr-auto px-2"><p><strong>Disciplines:</strong></p>
					<ul>
					<li>Asset Performance Management</li>
					<li>Predictive Analytics</li>
					<li>Automation</li>
					<li>Process Improvement</li>
					<li>Audit and Compliance</li>
					</ul>
					</div>
					</div>
                    <!--<a
                      href="javascript:void(0)"
                      class="font-normal text-lightBlue-400"
                    >
                      Learn more
                    </a>-->
					<br /><br />
                  </div>
                </div>
              </div>
              <div class="text-center  ml-auto mr-auto">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                >
					
                </h3>
				
            <div class="w-full md:w-8/12 ml-auto mr-auto">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src="processImg"
              />
			</div>
		</div>
		</div>
		<div class="mt-10 py-10 border-t border-blueGray-200  bg-clip-content text-center">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 mb-2"
                >
                  Our Ecosystem
                </h3>
				
				<div class="flex flex-wrap">
					<div class="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
					<img
					alt="..."
					class="max-w-full md:w-2/12 rounded-lg shadow-lg"
					:src="awsImg"
					/>
					</div>
					<div class="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
					<img
					alt="..."
					class="max-w-full rounded-lg shadow-lg"
					:src="msImg"
					/>
					</div>
					<div class="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
					<img
					alt="..."
					class="max-w-full md:w-2/12 rounded-lg shadow-lg"
					:src="googleImg"
					/>
					</div>
					<div class="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
					<img
					alt="..."
					class="max-w-full rounded-lg shadow-lg"
					:src="oracleImg"
					/>
					</div>
					<!--
					<div class="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
					<img
					alt="..."
					class="max-w-full rounded-lg shadow-lg"
					:src="doImg"
					/>
					</div>
					-->
				</div>
				<div class="w-full md:w-4/12 ml-auto mr-auto px-4">
				</div>
                <div class="flex flex-wrap justify-center" style="visibility: false; display: none;">
					With deep expertise in leveraging
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed">
					Elastic Search
                    </p>
                    <p class="mb-4 text-lg leading-relaxed">
					JAVA
                    </p>
                    <p class="mb-4 text-lg leading-relaxed">
					NodeJS
                    </p>
                    <a
                      href="javascript:void(0)"
                      class="font-normal text-emerald-500"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
        </div>
      </section>
		<contact-component />
    </main>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ContactComponent from "@/components/Contact/ContactForm.vue";

import processImg from "@/assets/img/process.png";
import awsImg from "@/assets/img/ecosystem/Aws-Logo-660x330.png";
import doImg from "@/assets/img/ecosystem/do-Logo-660x330.png";
import googleImg from "@/assets/img/ecosystem/Google-Logo-660x330.png";
import msImg from "@/assets/img/ecosystem/MicrosoftAzure-Logo-660x330.png";
import oracleImg from "@/assets/img/ecosystem/Oracle-Logo-660x330.png";

export default {
  data() {
    return {
	processImg,
	awsImg,
	doImg,
	googleImg,
	msImg,
	oracleImg
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
	ContactComponent,
  },
};
</script>
